import { FC } from 'react'

type ChangeHandler = (value: string) => void

type ContentTypeFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string
}

export const ContentTypeFilter: FC<ContentTypeFilterArgs> =
    ({
        value,
        onChange,
        className
    }) =>
        <span className={className}>
            <label>Type</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Content Type'
            >
                <option value='all'>All</option>
                <option value='article'>Article</option>
                <option value='database'>Database</option>
                <option value='event'>Event</option>
                <option value='insight-report'>Insight report</option>
                <option value='magazine'>Magazine</option>
                <option value="newsletter">Newsletter</option>
                <option value='market-report'>Market report</option>
                <option value='polymer-demand'>Polymer demand</option>
            </select>
            </span>
